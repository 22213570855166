import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import {
  Card,
  CardContent,
  Button,
  FormGroup,
  FormControl,
  Input,
  InputLabel,
  Snackbar,
  SnackbarContent
} from "@material-ui/core";

import { getPermissions } from "../../middleware/api";
import { PermissionsContext } from "contexts/PermissionsContext";

const LoginForm = () => {
  const [snackbar, setSnackbar] = useState({ open: false });
  const [credentials = { email: "", password: "" }, setCredentials] = useState(
    {}
  );
  const { permissions, handlePermissionChange } = useContext(
    PermissionsContext
  );
  const history = useHistory();

  if (permissions) {
    history.push({
      pathname: "/dashboard"
    });
  }

  const handleInputChange = event => {
    const value = event.target.value;
    const type = event.target.type;

    setCredentials(prevState => ({
      ...prevState,
      [type]: value
    }));
  };

  const handleInputKeyPress = event => {
    if (event.key === "Enter") {
      logUserIn();
    }
  };

  const showSnackbar = (open, variant, message) => {
    setSnackbar({ open, variant, message });
  };

  const onSnackbarClose = () => {
    setSnackbar({ open: false });
  };

  const logUserIn = async () => {
    if (!credentials.email || !credentials.password) {
      showSnackbar(true, "danger", "Please enter your email and password");
      return false;
    }

    const permissionsFromAPI = await getPermissions(credentials);

    if (!permissionsFromAPI) {
      showSnackbar(
        true,
        "danger",
        "Invalid email or password. Please try again."
      );

      return false;
    }

    handlePermissionChange(permissionsFromAPI);

    history.push({
      pathname: "/dashboard"
    });
  };

  return (
    <div className="login">
      <h1 className="login__header">Bode</h1>
      <Card className="login__form">
        <CardContent>
          <FormGroup>
            <FormControl>
              <InputLabel htmlFor="email">Email address</InputLabel>
              <Input
                aria-describedby="email"
                autoFocus
                id="email"
                onChange={handleInputChange}
                onKeyPress={handleInputKeyPress}
                type="email"
              />
            </FormControl>
            <FormControl>
              <InputLabel htmlFor="password">Password</InputLabel>
              <Input
                aria-describedby="password"
                id="password"
                onChange={handleInputChange}
                onKeyPress={handleInputKeyPress}
                type="password"
              />
            </FormControl>
            <FormControl>
              <Button
                color="primary"
                onClick={logUserIn}
                size="medium"
                type="submit"
                variant="contained"
              >
                Login
              </Button>
            </FormControl>
          </FormGroup>
        </CardContent>
      </Card>
      {snackbar.open && (
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          autoHideDuration={5000}
          onClose={onSnackbarClose}
          open={snackbar.open}
          variant={snackbar.variant}
        >
          <SnackbarContent
            className="snackbar-message"
            message={snackbar.message}
          />
        </Snackbar>
      )}
    </div>
  );
};

export default LoginForm;
