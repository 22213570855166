import React, { useState } from 'react'
import { AppBar, Toolbar, IconButton, Typography } from '@material-ui/core'

import MenuIcon from '@material-ui/icons/Menu'
import SideMenu from './SideMenu'

export default function Navigation() {
  const [menuState, setMenuState] = useState(false)

  const toggleDrawer = () => {
    setMenuState(!menuState)
  }

  const closeDrawer = () => {
    setMenuState(false)
  }

  return (
    <>
      <AppBar className="header__container" position="fixed">
        <Toolbar className="header">
          <IconButton
            aria-label="menu"
            className="hamburger"
            color="inherit"
            onClick={toggleDrawer}
          >
            <MenuIcon />
          </IconButton>
          <Typography classes={{ h6: 'logo' }} variant="h6">
            Bode
          </Typography>
        </Toolbar>
      </AppBar>
      <SideMenu isOpen={menuState} openCallback={closeDrawer} />
    </>
  )
}
