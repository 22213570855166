import React from "react";
import PropTypes from "prop-types";

import StatItem from "../StatItem";
import { statItems } from "../../../_data/statItems.json";

export default function TopPanel({ property_id }) {
  const statComponents = statItems.map((item, index) => (
    <StatItem key={index} property_id={property_id} stat={item} />
  ));

  return <div className="top-panel">{statComponents}</div>;
}

TopPanel.propTypes = {
  property_id: PropTypes.string
};
