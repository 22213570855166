import React from 'react'
import styled, { keyframes } from 'styled-components'
import PropTypes from 'prop-types'

export default function NightsAndRevenueBar({ revenue, roomNights, monthName, totalRevenue }) {
  const barWidth = totalRevenue ? (parseInt(revenue) / totalRevenue) * 100 : 0

  const animateBarWidth = keyframes`{
    0% {
      width: 0;
    }
    100% {
      width: ${barWidth}%;
    }
  }`

  const AnimatedBar = styled.div`
    animation: ${animateBarWidth} 1s ease-in-out;
    width: ${barWidth}%;
  `

  const showRevenue = revenue !== undefined

  return (
    <div className="bar-graph__item">
      <div className="bar-graph__header">
        <div className="bar-graph__month">{monthName}</div>
        <div className="bar-graph__text">
          { showRevenue && `$ ${parseInt(revenue)} • ` }{roomNights} Room Nights
        </div>
      </div>
      { showRevenue && (
        <div className="bar-graph__bar">
          <AnimatedBar className="bar-graph__bar -inner" />
        </div>
      )}
    </div>
  )
}

NightsAndRevenueBar.propTypes = {
  revenue: PropTypes.number,
  roomNights: PropTypes.number,
  monthName: PropTypes.string,
  totalRevenue: PropTypes.number,
}
