import React, { createContext, useState } from 'react'
import PropTypes from 'prop-types'

const PermissionsContext = createContext()

const PermissionsProvider = ({ children }) => {
  const [permissions, setPermissions] = useState()

  const handlePermissionChange = permissions => {
    setPermissions(permissions)
    localStorage.setItem('bodePermissions', JSON.stringify(permissions))
  }

  return (
    <PermissionsContext.Provider value={{ permissions, handlePermissionChange }}>
      {children}
    </PermissionsContext.Provider>
  )
}

export { PermissionsContext, PermissionsProvider }

PermissionsProvider.propTypes = {
  children: PropTypes.object,
}
