import { TODAY } from "./date";
import axios from "axios";

const REACT_APP_API_URL = "https://ops.bodeapp.com/v2";

export const getPermissions = async ({ email, password }) => {
  const response = await fetch(REACT_APP_API_URL + "/auth/", {
    method: "POST",
    body: JSON.stringify({ email, password }),
    credentials: "include",
    headers: { "Content-Type": "application/json", Accept: "application/json" }
  });

  const permissions = await response.json();

  return permissions.scopes;
};

export const getPolicies = async (propertyId, statName) => {
  const response = await axios.get(
    REACT_APP_API_URL +
      "/properties/" +
      propertyId +
      "/policies?endpoint_names%5B%5D=" +
      statName,
    { withCredentials: true }
  );

  return response.data;
};

export const getStatistics = async ({ property_id, statName }) => {
  const policies = await getPolicies(property_id, statName);

  if (policies && !policies[statName].show) {
    return false;
  }

  const dateParam = `at=${TODAY}`;
  const response = await axios.get(
    `${REACT_APP_API_URL}/properties/${property_id}/${statName}?${dateParam}/`,
    { withCredentials: true }
  );

  return response.data.data;
};

export const getGraphBarValues = (
  { property_id, startDate, endDate },
  source
) => {
  const statNames = ["revenue_booked", "nights_booked_count"];

  const dateParam = `at=${TODAY}&from=${startDate}&to=${endDate}`;

  const promises = statNames.map(async statName => {
    const policies = await getPolicies(property_id, statName);

    if (policies && !policies[statName].show) {
      return false;
    }

    return axios.get(
      `${REACT_APP_API_URL}/properties/${property_id}/${statName}?${dateParam}/`,
      {
        cancelToken: source.token,
        withCredentials: true
      }
    );
  });

  return Promise.all(promises);
};

export const getRevenueBarValues = ({
  property_id,
  startDate,
  endDate,
  monthNumber,
  year
}) => {
  const statNames = ["shopify_revenue", "revenue_booked", "revenue_target"];

  const promises = statNames.map(async statName => {
    const policies = await getPolicies(property_id, statName);
    if (policies && !policies[statName].show) {
      return false;
    }
    let dateParam = `from=${startDate}&to=${endDate}`;

    if (statName === "revenue_target")
      dateParam = `year=${year}&month=${monthNumber}`;

    return axios.get(
      `${REACT_APP_API_URL}/properties/${property_id}/${statName}?${dateParam}`,
      {
        withCredentials: true
      }
    );
  });

  return Promise.all(promises);
};
