import React from 'react'
import { HashRouter, Switch, Route } from 'react-router-dom'

import './App.scss'

import LoginPage from 'pages/LoginPage'
import DashboardPage from 'pages/DashboardPage'

import { PermissionsProvider } from 'contexts/PermissionsContext.js'

const App = () => {
  return (
    <div className="App">
      <PermissionsProvider>
        <HashRouter basename="/">
          <Switch>
            <Route exact path="/">
              <LoginPage />
            </Route>
            <Route path="/dashboard">
              <DashboardPage />
            </Route>
          </Switch>
        </HashRouter>
      </PermissionsProvider>
    </div>
  )
}

export default App
