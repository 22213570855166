import React from "react";
import PropTypes from "prop-types";
import { Card, CardHeader, CardContent } from "@material-ui/core";

import TopPanel from "./TopPanel/TopPanel";
import NightsAndRevenueGraph from "./NightsAndRevenue/NightsAndRevenueGraph";
import Loader from "../Utils/Loader/Loader";
import ErrorBoundary from "errorBoundaries/ErrorBoundary";
import BenchmarkingVsBudgetedGraph from "./BenchmarkingVsBudgetedGraph/BenchmarkingVsBudgetedGraph";

const PropertyCard = ({ property: { property_id, property_name } }) => {
  const header = property_name ? (
    <CardHeader className="block-header" title={`${property_name}`} />
  ) : (
    <div className="block-header">
      <Loader />
    </div>
  );
  return (
    <Card className="block">
      {header}
      <CardContent>
        <ErrorBoundary className="top-panel">
          <TopPanel property_id={property_id} />
        </ErrorBoundary>
        <ErrorBoundary className="bar-graph">
          <NightsAndRevenueGraph property_id={property_id} />
        </ErrorBoundary>
        <ErrorBoundary className="bar-graph">
          <BenchmarkingVsBudgetedGraph property_id={property_id} />
        </ErrorBoundary>
      </CardContent>
    </Card>
  );
};

export default PropertyCard;

PropertyCard.propTypes = {
  property_id: PropTypes.string,
  property_name: PropTypes.string
};
