import React from 'react'
import LoginForm from 'components/Login/LoginForm'

export default function LoginPage() {
  return (
    <>
      <LoginForm />
    </>
  )
}
