import React from "react";
import styled, { keyframes } from "styled-components";
import PropTypes from "prop-types";

export default function BenchmarkingVsBudgetedBar({
  monthName,
  revenueBooked,
  revenueTarget
}) {
  const barStatus = revenueTarget
    ? (parseInt(revenueBooked) / revenueTarget) * 100
    : 0;

  const barWidth = barStatus > 100 ? 100 : barStatus;

  const kFormatter = num =>
    Math.abs(num) > 999
      ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(0)
      : Math.sign(num) * Math.abs(num);

  const revenueBookedFormatted = kFormatter(revenueBooked)
  const revenueTargetFormatted = kFormatter(revenueTarget)

  const animateBarWidth = keyframes`{
    0% {
      width: 0;
    }
    100% {
      width: ${barWidth}%;
    }
  }`;

  const AnimatedBar = styled.div`
    animation: ${animateBarWidth} 1s ease-in-out;
    width: ${barWidth}%;
  `;

  const showRevenue = revenueTarget !== undefined;

  return (
    <div className="bar-graph__item">
      <div className="bar-graph__header">
        <div className="bar-graph__month">{monthName}</div>
        <div className="bar-graph__text">
          Goal: {revenueTarget && `$ ${revenueTargetFormatted} K`}
        </div>
      </div>
      {showRevenue && (
        <div className="bar-graph__bar -heigher">
          <AnimatedBar className="bar-graph__bar -heigher -inner" />
          <div className="bar-graph__stats">${revenueBookedFormatted}K / {barWidth.toFixed(0)}%</div>
        </div>
      )}
    </div>
  );
}

BenchmarkingVsBudgetedBar.propTypes = {
  monthName: PropTypes.string,
  revenueBooked: PropTypes.number,
  revenueTarget: PropTypes.number
};
