import React, { useEffect, useState } from "react";
import Loader from "../../Utils/Loader/Loader";
import BenchmarkingVsBudgetedBar from "./BenchmarkingVsBudgetedBar";
import PropTypes from "prop-types";

import { monthsToDisplay } from "../../../middleware/date";

import { getRevenueBarValues } from "../../../middleware/api";

const BenchmarkingVsBudgetedGraph = ({ property_id }) => {
  const [bars, setBars] = useState([]);

  useEffect(() => {
    const monthNames = [];

    const promises = monthsToDisplay.map(
      ({ monthName, startDate, endDate, monthNumber, year }) => {
        monthNames.push(monthName);

        const payload = {
          property_id,
          startDate,
          endDate,
          monthNumber,
          year
        };

        return getRevenueBarValues(payload);
      }
    );

    Promise.all(promises).then(bars => {
      const { monthName } = bars;
      const barData = bars.map((endpoints, index) => {
        const barPayload = {};

        endpoints &&
          endpoints.map(endpoint => {
            if (!endpoint) return false;
            const {
              data: { data }
            } = endpoint;

            if (data.id === "revenue_booked")
              barPayload.revenue_booked = data.attributes.value;
            if (data.id === "shopify_revenue")
              barPayload.shopify_revenue = data.attributes.value;
            if (data.id === "revenue_target")
              barPayload.revenue_target = data.attributes.value;
            barPayload.monthName = monthName;
            return barPayload;
          });
        barPayload.monthName = monthNames[index];

        return barPayload;
      });

      setBars(barData);
    });
  }, [property_id]);

  if (bars.length === 0) {
    return (
      <div className="bar-graph">
        <Loader />
      </div>
    );
  }

  return (
    <div className="bar-graph">
      <div className="bar-graph__title">Revenue goals</div>
      {bars.map((bar, index) => {
        const revenueBooked = bar.revenue_booked + bar.shopify_revenue;
        return (
          <BenchmarkingVsBudgetedBar
            key={index}
            {...bar}
            revenueBooked={revenueBooked}
            revenueTarget={bar.revenue_target}
          />
        );
      })}
    </div>
  );
};

BenchmarkingVsBudgetedGraph.propTypes = {
  property_id: PropTypes.string
};

export default BenchmarkingVsBudgetedGraph;
