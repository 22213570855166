import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";

import { monthsToDisplay } from "middleware/date";
import { getGraphBarValues } from "middleware/api";

import NightsAndRevenueBar from "./NightsAndRevenueBar";
import Loader from "../../Utils/Loader/Loader";

export default function NightsAndRevenueGraph({ property_id }) {
  const [bars, setBars] = useState([]);
  const [totalRevenue, setTotalRevenue] = useState("N/A");

  useEffect(() => {
    window.source = axios.CancelToken.source();
    const monthNames = [];
    const promises = monthsToDisplay.map(
      ({ monthName, startDate, endDate }) => {
        const payload = {
          property_id,
          startDate,
          endDate,
          monthName
        };

        monthNames.push(monthName);

        return getGraphBarValues(payload, window.source);
      }
    );

    Promise.all(promises).then(bars => {
      const { monthName } = bars;
      const barData = bars.map((endpoints, index) => {
        const barPayload = {};

        endpoints &&
          endpoints.map(endpoint => {
            if (!endpoint) return false;

            const {
              data: { data }
            } = endpoint;
            if (data.id.includes("revenue"))
              barPayload.revenue = data.attributes.value;
            if (data.id.includes("nights"))
              barPayload.roomNights = data.attributes.value;
            barPayload.monthName = monthName;

            return barPayload;
          });

        barPayload.monthName = monthNames[index];
        return barPayload;
      });

      const total = barData.reduce((accumulator, bar) => {
        return accumulator + bar.revenue;
      }, 0);

      setTotalRevenue(total);
      setBars(barData);
    });
    return () => {
      window.source.cancel();
    };
  }, [property_id]);

  if (totalRevenue === "N/A") {
    return (
      <div className="bar-graph">
        <Loader />
      </div>
    );
  }

  return (
    <div className="bar-graph">
      {bars.map((bar, index) => (
        <NightsAndRevenueBar key={index} {...bar} totalRevenue={totalRevenue} />
      ))}
    </div>
  );
}

NightsAndRevenueGraph.propTypes = {
  property_id: PropTypes.string
};
