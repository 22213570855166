import React, { useContext } from "react";
import { Redirect } from "react-router-dom";

import PropertyCard from "./PropertyCard";

import { PermissionsContext } from "contexts/PermissionsContext";

const Dashboard = () => {
  let { permissions } = useContext(PermissionsContext);
  const permisionsFromLocalStorage = JSON.parse(
    localStorage.getItem("bodePermissions")
  );

  permissions = permissions ? permissions : permisionsFromLocalStorage;

  if (!permissions) {
    return <Redirect to="/" />;
  }

  return (
    <div className="dashboard">
      {permissions.map((property, index) => {
        return <PropertyCard property={property} key={index} />;
      })}
    </div>
  );
};

export default Dashboard;
