import moment from "moment";
import { graphConfig } from "../_data/graphConfig.json";
import { range } from "lodash";

const { MONTHS_TO_SHOW } = graphConfig;

export const TODAY = moment().format("YYYY-MM-DD");

export const monthsToDisplay = [];

range(MONTHS_TO_SHOW).forEach(index => {
  const month = moment().add(index, "months");
  monthsToDisplay.push({
    monthName: month.format("MMMM"),
    startDate: month.startOf("month").format("YYYY-MM-DD"),
    endDate: month.endOf("month").format("YYYY-MM-DD"),
    monthNumber: month.format("M"),
    year: month.format("YYYY")
  });
});
