import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { getStatistics } from "../../middleware/api";
import Loader from "./../Utils/Loader/Loader";

export default function StatItem({
  stat: { statName, displayedText },
  property_id
}) {
  const [itemCount, setItemCount] = useState(null);

  useEffect(
    () => {
      (async function() {
        const payload = {
          property_id,
          statName
        };
        const statistics = await getStatistics(payload);
        if (!statistics) {
          setItemCount(false);
          return false;
        }
        setItemCount(statistics.attributes.value);
      })();
    },
    [property_id, statName]
  );

  const currency = displayedText.includes("revenue") ? "$" : "";

  if (itemCount === false) {
    return null;
  }

  if (itemCount === null) {
    return (
      <div className="count-box">
        <Loader />
      </div>
    );
  }

  return (
    <div className="count-box">
      <div className="count-box__number">{`${currency} ${parseInt(
        itemCount
      )}`}</div>
      <div className="count-box__text">{displayedText}</div>
    </div>
  );
}

StatItem.propTypes = {
  stat: PropTypes.shape({
    statName: PropTypes.string,
    displayedText: PropTypes.string
  }),
  property_id: PropTypes.string
};
