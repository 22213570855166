import React, { Component } from 'react'
import PropTypes from 'prop-types'

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  render() {
    const { className, children } = this.props

    if (this.state.hasError) {
      return (
        <div className={className}>
          <h1 className="error__heading">Service unavailable</h1>
        </div>
      )
    }

    return children
  }
}

export default ErrorBoundary

ErrorBoundary.propTypes = {
  className: PropTypes.string,
  children: PropTypes.object,
}
