import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Drawer, List, ListItem, ListItemText } from '@material-ui/core'

import { PermissionsContext } from 'contexts/PermissionsContext'

const SideMenu = ({ isOpen, openCallback }) => {
  const { handlePermissionChange } = useContext(PermissionsContext)

  const logUserOut = () => {
    handlePermissionChange(null)
  }
  return (
    <Drawer classes={{ paper: 'side-menu' }} onClose={() => openCallback(false)} open={isOpen}>
      <List>
        <ListItem button>
          <ListItemText onClick={logUserOut} primary="Log Out" />
        </ListItem>
      </List>
    </Drawer>
  )
}

export default SideMenu

SideMenu.propTypes = {
  isOpen: PropTypes.bool,
  openCallback: PropTypes.func,
}
