import React from 'react'
import Navigation from 'components/Navigation/Navigation'
import Dashboard from 'components/Dashboard/Dashboard'

export default function DashboardPage() {
  return (
    <>
      <Navigation />
      <Dashboard />
    </>
  )
}
